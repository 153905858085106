import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><inlineCode parentName="p">{`Button`}</inlineCode>{` is used for actions, like in forms, while `}<inlineCode parentName="p">{`Link`}</inlineCode>{` is used for destinations, or moving from one page to another.`}</p>
    <p>{`In special cases where you'd like to use a `}<inlineCode parentName="p">{`<a>`}</inlineCode>{` styled like a Button, use `}<inlineCode parentName="p">{`<Button as="a">`}</inlineCode>{` and provide an `}<inlineCode parentName="p">{`href`}</inlineCode>{`, or it can be some other tag.`}</p>
    <h2>{`Figma`}</h2>
    <p>{`🎨 `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/MkKnvUYVRT7ovGA2TT2YnQjm/Uchi.Doma-GUI?type=design&node-id=6850-5991&t=IrMkseSQC1wzsdJR-0"
      }}>{`Figma`}</a></p>
    <h2>{`Import`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Button } from '@uchiru/components'
`}</code></pre>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Button variant="mainPrimary">Button</Button>
`}</code></pre>
    <h3>{`Button Sizes`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Flex alignItems="flex-start" style={{ gap: '8px' }}>
  <Button variant="mainPrimary" scale="sm">Button</Button>
  <Button variant="mainPrimary" scale="md">Button</Button>
  <Button variant="mainPrimary" scale="lg">Button</Button>
  <Button variant="mainPrimary" scale="xl">Button</Button>
</Flex>
`}</code></pre>
    <h3>{`Button Colors`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Flex alignItems="center" style={{ gap: '8px', padding: '20px' }}>
  <Button variant="mainPrimary">Button</Button>
  <Button variant="mainSecondary">Button</Button>
  <Button variant="dangerPrimary">Button</Button>
  <div style={{ color: '#888', padding: '0 10px' }}>|</div>
  <Button variant="mainPrimary" disabled>Button</Button>
  <Button variant="mainSecondary" disabled>Button</Button>
</Flex>
<Flex alignItems="center" style={{ gap: '8px', padding: '20px', background: '#333' }}>
  <Button variant="whitePrimary">Button</Button>
  <Button variant="whiteSecondary">Button</Button>
  <div style={{ color: '#888', padding: '0 10px' }}>|</div>
  <Button variant="whitePrimary" disabled>Button</Button>
  <Button variant="whiteSecondary" disabled>Button</Button>
</Flex>
`}</code></pre>
    <h2>{`Button Icons`}</h2>
    <p>{`Button supports displaying icon with `}<inlineCode parentName="p">{`icon`}</inlineCode>{` prop in 2 modes: with text or with icon only if content of `}<inlineCode parentName="p">{`Button`}</inlineCode>{` is omitted. `}<a parentName="p" {...{
        "href": "https://github.com/uchiru/components/blob/master/src/Icon/constants.js"
      }}>{`List of icons`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Flex style={{ gap: '8px', padding: '20px' }}>
  <Button variant="mainPrimary" icon="plus-circled">Button</Button>
  <Button variant="mainSecondary" icon="bin">Button</Button>
</Flex>

<Flex alignItems="flex-start" style={{ gap: '8px', padding: '20px', background: '#eee' }}>
  <Button scale="sm" icon="plus-circled" />
  <Button scale="md" icon="plus-circled" />
  <Button scale="lg" icon="plus-circled" />
  <Button scale="xl" icon="plus-circled" />
</Flex>

<Flex alignItems="flex-start" style={{ gap: '8px', padding: '20px', background: '#d8d8d8' }}>
  <Button scale="sm" icon="plus-circled">Button</Button>
  <Button scale="md" icon="plus-circled">Button</Button>
  <Button scale="lg" icon="plus-circled">Button</Button>
  <Button scale="xl" icon="plus-circled">Button</Button>
</Flex>

<Flex style={{ gap: '8px', padding: '20px', background: '#333' }}>
  <Button variant="whitePrimary" icon="plus-circled">Button</Button>
  <Button variant="whiteSecondary" icon="plus-circled">Button</Button>
</Flex>

<Flex alignItems="flex-start" style={{ gap: '8px', padding: '20px' }}>
  <Button icon="chevron-left" />
  <Button icon="chevron-right" />
  <Button icon="chevron-left-thin" />
  <Button icon="chevron-right-thin" />
  <Button icon="plus-circled" />
  <Button icon="product-python" />
  <Button icon="product-cpp" />
  <Button icon="bin" />
</Flex>
`}</code></pre>
    <h2>{`Button as Link`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Flex style={{ gap: '8px' }}>
  <Button as="a" href="#">Button</Button>
  <Button as={Link} to="#">Button</Button>
</Flex>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p><inlineCode parentName="p">{`Button`}</inlineCode>{` component get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{`, `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{`, `}<inlineCode parentName="p">{`TYPOGRAPHY`}</inlineCode>{`, `}<inlineCode parentName="p">{`ICON`}</inlineCode>{` and `}<inlineCode parentName="p">{`BORDER`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <p>{`Native `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` HTML attributes are forwarded to the underlying React `}<inlineCode parentName="p">{`button`}</inlineCode>{` component and are not listed below.`}</p>
    <h3>{`Some notable system props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`button`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets the HTML tag for the component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`fontSize`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Number or String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`explicitly sets the font size for the Button text; overrides any value for the `}<inlineCode parentName="td">{`variant`}</inlineCode>{` prop`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`display`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`explicitly sets the display method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Number or String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`explicitly sets the width`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Main component props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'mainPrimary'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a value of `}<inlineCode parentName="td">{`mainPrimary`}</inlineCode>{`, `}<inlineCode parentName="td">{`mainSecondary`}</inlineCode>{`, `}<inlineCode parentName="td">{`whitePrimary`}</inlineCode>{` and `}<inlineCode parentName="td">{`whiteSecondary`}</inlineCode>{` results in different button styles`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`scale`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'md'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a value of `}<inlineCode parentName="td">{`sm`}</inlineCode>{`, `}<inlineCode parentName="td">{`md`}</inlineCode>{`, `}<inlineCode parentName="td">{`lg`}</inlineCode>{` and `}<inlineCode parentName="td">{`xl`}</inlineCode>{` results in different button sizes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`name of `}<a parentName="td" {...{
              "href": "/atoms/Icon"
            }}>{`Icon`}</a></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      